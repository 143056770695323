import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import type { MenuProps } from "antd";
import { Menu } from "antd";

import {
  ContainerOutlined,
  CloudOutlined,
  DesktopOutlined,
  MenuOutlined,
  PieChartOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { googleLogout } from "@react-oauth/google";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { removeUserInfo } from "../../features/user/userSlice";
import { removeInstance } from "../../features/instance/instanceSlice";
import { removeSelectionInfo } from "../../features/selection/selectionSlice";
import { clearAllSessions } from "../../utility/common/session-store";

type MenuItem = Required<MenuProps>["items"][number];

export const MainMenu = () => {
  const { t } = useTranslation();
  const settingsState = useAppSelector((state: any) => state.settings);
  const [menuType, setMenuType] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuType(!menuType);
  };

  const navigateLink = (param: string) => {
    toggleMenu();
    navigate(param);
  };

  const mainMenuItems: MenuItem[] = [
    {
      key: "1",
      icon: <PieChartOutlined />,
      label: <div onClick={() => navigateLink("/")}>{t("Dashboard")}</div>,
    },
    {
      key: "2",
      icon: <UserOutlined />,
      label: <div onClick={() => navigateLink("/account")}>{t("Account")}</div>,
    },
    {
      key: "3",
      icon: <ContainerOutlined />,
      label: (
        <div onClick={() => navigateLink("/billings")}>{t("Billings")}</div>
      ),
    },
    {
      key: "4",
      icon: <CloudOutlined />,
      label: <div onClick={() => navigateLink("/cloud")}>{t("Cloud")}</div>,
    },
    {
      key: "5",
      icon: <DesktopOutlined />,
      label: (
        <div onClick={() => navigateLink("/instances")}>{t("Instances")}</div>
      ),
    },
  ];

  const bottomMenuItems: MenuItem[] = [
    {
      key: "0",
      label: (
        <a href={"./pdf/platform_tutorial.pdf"} target="_blank">
          <div onClick={() => console.log("Tutorial")}>{t("Tutorial")}</div>
        </a>
      ),
    },
    {
      key: "1",
      label: <div onClick={() => logOut()}>{t("Log Out")}</div>,
    },
  ];

  const logOut = () => {
    googleLogout();
    dispatch(removeUserInfo());
    dispatch(removeInstance());
    dispatch(removeSelectionInfo());
    clearAllSessions();
    navigate("/login");
  };

  return (
    <div className="main-menu">
      <aside>
        <div className="logo-wrapper">
          <Link to="/">
            <div className="logo" />
          </Link>
          <span className="mobile-menu" onClick={() => toggleMenu()}>
            <MenuOutlined />
          </span>
        </div>
        <div
          className={
            menuType ? "main-menu-wrapper" : "main-menu-wrapper show-mobile"
          }
        >
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={settingsState.menuKey}
            items={mainMenuItems}
          />
          <Menu theme="dark" items={bottomMenuItems} />
        </div>
      </aside>
    </div>
  );
};
