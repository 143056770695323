import axiosClient from "../axios";
import { ResultDataModel } from "../../utility/interface/api/result-data";

// gpu rental flow

interface rentGPUBlob {
  machine_id: number;
  user_id: number;
  template_id: number;

  gpu_count: number;
}

interface stopGPURentalBlob {
  instance_id: number;
  machine_id: number;
  user_id: number;
}

interface InstancePublicKeyBlob {
  instance_id: number;
  public_key: string;
}

export const getGPUHostList = (page_no: number, page_size: number) => {
  const url = `gpuHost`;
  const headers = {
    headers: {
      page_no,
      page_size,
    },
  };
  return axiosClient.get<ResultDataModel<any>>(url, headers);
};

export const getGPUInstnace = (user_id: number) => {
  const url = `gpuInstance`;
  const headers = {
    headers: {
      user_id,
    },
  };
  return axiosClient.get<ResultDataModel<any>>(url, headers);
};

export const getInstanceSSHCommand = (user_id: number, instance_id: number) => {
  const url = `getInstanceSSHCommand?instance_id=${instance_id}`;
  const headers = {
    headers: {
      user_id,
    },
  };
  return axiosClient.get<ResultDataModel<any>>(url, headers);
};

export const updateInstancePublicKey = (
  user_id: number,
  InstancePublicKeyBlob: InstancePublicKeyBlob
) => {
  const url = `updateInstancePublicKey`;
  const headers = {
    headers: {
      user_id,
    },
  };
  const data = {
    ...InstancePublicKeyBlob,
  };
  return axiosClient.post<ResultDataModel<any>>(url, data, headers);
};

// GPU rental, stop rental

export const rentGPU = (rentGPUBlob: rentGPUBlob) => {
  const url = `gpuRent`;
  let body = {
    ...rentGPUBlob,
  };
  return axiosClient.post<ResultDataModel<any>>(url, body);
};

export const stopGPURent = (stopGPURentalBlob: stopGPURentalBlob) => {
  const url = `stopGpuRent`;
  let body = {
    ...stopGPURentalBlob,
  };
  return axiosClient.post<ResultDataModel<any>>(url, body);
};

// get template

export const getTemplate = (gpu_type: number) => {
  const url = `template`;
  const headers = {
    headers: {
      gpu_type,
    },
  };
  return axiosClient.get<ResultDataModel<any>>(url, headers);
};

// get rental history

export const getRentalHistory = (user_id: number) => {
  const url = `rentalHistory`;
  const headers = {
    headers: {
      user_id,
    },
  };
  return axiosClient.get<ResultDataModel<any>>(url, headers);
};
