import "../../../assets/scss/account-style.scss";
import { useEffect, useState } from "react";
import { Descriptions, DescriptionsProps, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import Chart from "react-google-charts";

import { useAppSelector } from "../../store/hooks";
import { getRentalHistory } from "../../server/GPU";

export const Account = () => {
  const { t } = useTranslation();
  const userState = useAppSelector((state: any) => state.user);
  const [gpuDataType, setGpuDataType] = useState<any[]>([]);
  const [templateDataType, setTemplateDataType] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const items: DescriptionsProps["items"] = [
    {
      key: "1",
      label: t("Name"),
      children: (
        <>
          {userState.profile.given_name} {userState.profile.family_name}
        </>
      ),
      span: 3,
    },
    {
      key: "2",
      label: t("E-Mail"),
      children: <>{userState.profile.email}</>,
      span: 3,
    },
  ];

  const GPUData = [["GPU Type", "Used Times"], ...gpuDataType];
  const TemplateData = [["Template Name", "Used Times"], ...templateDataType];

  const countOccurrences = (itemToFind: string, dataArray: any[]) => {
    const countMap: any = {};

    // Count occurrences
    dataArray.forEach((item: any) => {
      const itemName = item[itemToFind];
      const baseName = itemName.replace(/-\d+$/, "");
      if (baseName) {
        countMap[baseName] = (countMap[baseName] || 0) + 1;
      }
    });

    // Convert to an array of objects
    return Object.entries(countMap);
  };

  const getRentalHistoryData = () => {
    setLoading(true);
    getRentalHistory(userState.user_id)
      .then((res: any) => {
        let data = res.data.rental_history;
        let productName = countOccurrences("product_name", data);
        let templateData = countOccurrences("template_name", data);
        setGpuDataType(productName);
        setTemplateDataType(templateData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRentalHistoryData();
  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="upper-info">
          <h4>{t("User Info")}</h4>
          <div className="user-details">
            <>
              <img
                style={{ borderRadius: "12%", width: "96px" }}
                src={`${
                  userState.profile.picture == ""
                    ? "/images/user/user_icon.png"
                    : userState.profile.picture
                }`}
                alt="user image"
              />
            </>
            <Descriptions items={items} />
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="charts-wrapper">
          {loading ? (
            <Skeleton active />
          ) : gpuDataType.length == 0 ? (
            <div>
              <h4>{t("Machine Use Preference")}</h4>
              <p>{t("No Data Yet")}</p>
            </div>
          ) : (
            <>
              <Chart
                chartType="PieChart"
                data={GPUData}
                options={{
                  title: t("Machine Use Preference"),
                  is3D: true,
                  colors: [
                    "#1a1a1a",
                    "#3a3a3a",
                    "#9D9698",
                    "#cecacb",
                    "#d7d5d5",
                  ],
                }}
                height={"400px"}
              />
              <Chart
                chartType="PieChart"
                data={TemplateData}
                options={{
                  title: t("Template Use Preference"),
                  is3D: true,
                  colors: [
                    "#1a1a1a",
                    "#3a3a3a",
                    "#9D9698",
                    "#cecacb",
                    "#d7d5d5",
                  ],
                }}
                height={"400px"}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
